<template>
    <div>
      <article>
        <section>
          <div class="country_dialogs">
            <el-dialog
              :title="`Add To Bookings`"
              :visible.sync="isAddToBookingsDialogVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <!-- {{ reservationData }} <br/><br/>
                {{ reservationData.reservationDataID }}<br/><br/>
                {{ rooms }} -->
                <el-form
                  :model="roomForm"
                  :rules="rules"
                  label-position="top"
                  ref="roomForm"
                  class="demo-roomForm2"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item
                        label="Choose Payment Status"
                        prop="paymentStatus"
                      >     
                      <!-- {{ roomForm.paymentStatus }} -->
                        <el-radio-group v-model="roomForm.paymentStatus">
                          <el-radio label="PENDING">Pending</el-radio>
                          <el-radio label="PARTIAL">Partial</el-radio>
                          <el-radio label="FULL">Full Amount</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item
                        label="Choose Room"
                        prop="roomID"
                      >
                        <el-select v-model="roomForm.roomID" placeholder="Select">
                            <el-option
                                v-for="item in rooms"
                                :key="item.roomID"
                                :label="item.roomNumber"
                                :value="item.roomID">
                            </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: #083628; color: white;"
                      @click="submitForm('roomForm')"
                    >Add To Bookings</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2022 Acaki Lodge, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        isLoading: false,
        isLoadingError: false,
        rooms: [],
  
        isAddToBookingsDialogVisible: false,
        loadingError: false,
        submitting: false,
        roomForm: {
          roomID: "",
          paymentStatus:""
        },
  
        rules: {
          roomID: [
            {
              required: true,
              message: "Room Name is required",
              trigger: "blur",
            },
          ],
          paymentStatus: [
            {
              required: true,
              message: "Payment Status is required",
              trigger: "change",
            },
          ],
        },
      };
    },
  
    props: {
      showAddToBookingsDialog: {
        required: true,
        type: Boolean,
      },
      reservationData: {
        required: true,
        type: Object,
      },
    },
  
    watch: {
      showAddToBookingsDialog() {
        if (this.showAddToBookingsDialog === true) {
          this.isAddToBookingsDialogVisible = true;
          this.getCategoryRooms();
        } else {
          this.isAddToBookingsDialogVisible = false;
        }
      },
    },
  
    methods: {
        handleClose(done) {
            done();
            this.roomForm.roomID = "";
            this.$emit("closeAddToBookingsDialog");
        },

        async getCategoryRooms() {
            this.isLoading = true;
            this.isLoadingError = false;
            try {
            //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
            let request = await this.$http.get(`rooms/get-rooms/${this.reservationData.roomCategoryID}`);
            if (request.data.success && request.data.message === "ROOMS_FETCHED_SUCCESSFULLY") {
                this.rooms = request.data.rooms;
            } else throw "UNEXPECTED_RESPONSE_RECEIVED";
            } catch (error) {
            this.isLoadingError = true;
            //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
            this.$notify({
                title: "Operation Failed",
                message: "Unable to fetch rooms now, please try again",
                type: "error",
            });
            } finally {
            this.isLoading = false;
            }
        },

        async submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                this.loading = true;   
                try {
                    let request = await this.$http.post(
                        `bookings`, {
                            roomID: this.roomForm.roomID,
                            paymentStatus: this.roomForm.paymentStatus,
                            customerID: this.reservationData.customerID,
                            checkInDate: this.reservationData.checkInDate,
                            checkOutDate: this.reservationData.checkOutDate,
                            totalAmount: this.reservationData.totalAmount,
                            numberOfAdults: this.reservationData.numberOfAdults,
                            numberOfChildren: this.reservationData.numberOfChildren,
                            specialRequests: this.reservationData.specialRequests,
                            reservationStatus: "CONFIRMED",
                            reservationID: this.reservationData.reservationID
                        }
                    );
                    if (
                    request.data.success &&
                    request.data.message == "BOOKING_CREATED_SUCCESSFULLY"
                    ) {
                    // let redirectUrl = request.data.card_processor;
                    // window.location.replace(redirectUrl);
                    // this.$router.push({
                    //     name: "Payment",
                    //     query: { request: 'successful' },
                    // });
                    this.$emit("closeAddToBookingsDialog");
                    this.$emit("re-fresh");

                    return this.$notify.success({
                        title: 'Success',
                        message: 'Booking created Successfully'
                    });

                    } else {
                    throw "UNEXPECTED_RESPONSE";
                    }
                } catch (error) {
                    if (error.message == "Network Error") {
                    return this.$notify.error({
                        title: 'Connection failed',
                        message: 'A network error occurred, please try again.'
                    });
                    }
                    this.$notify.error({
                        title: 'Loading Failed',
                        message: 'Unable to place a Booking now, Please try again later'
                    });
                } finally {
                    this.loading = false;
                }
                } else {
                this.$notify.error({
                    title: 'Error',
                    message: 'Unable to place a reservation.'
                });
                return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },


    },
  };
  </script>
  
  <style>
  .el-select {
    width: 100%;
  }
  </style>